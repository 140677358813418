
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.my_subscription') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
           <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_type')"
                  label-for="institute_type_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_type_id"
                  :options="publicationTypeList"
                  id="institute_type_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_title')"
                  label-for="pub_title_id"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_title_id"
                  :options="publicationTitleList"
                  id="pub_title_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
          </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_sidebar.subscription_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(subscribe_auto_id)="data">
                      <span>{{ data.item.subscribe_auto_id }}</span>
                    </template>
                    <template v-slot:cell(subscription_date)="data">
                      <span>{{$t('external_research.subscription_type')}}: {{  $i18n.locale == 'bn' ? data.item.fee_type_name_bn : data.item.fee_type_name }}</span><br/>
                      <span>{{$t('external_research.number_of_subscription')}}: {{ $n(data.item.number_of_subscription) }}</span><br/>
                      <span>{{$t('globalTrans.subscription_start_date')}}: {{ data.item.start_date | dateFormat }}</span><br/>
                      <span>{{$t('globalTrans.subscription_end_date')}}: {{ data.item.end_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(pub_total_fee)="data">
                      <span>{{ $n(data.item.pub_total_fee, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(pub_number)="data">
                      <span>{{ $n(data.item.pub_number, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success mr-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="viewTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import Details from './Details.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { subscriptionListApi, subscriptionFormListApiPaymentStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
        editItemId: 0,
        search: {
            pub_type_id: 0,
            pub_title_id: 0
        },
         publicationTitleList: []
    }
  },
  computed: {
    publicationTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.filter(itm => itm.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    viewTitle () {
       return this.$t('external_sidebar.subscription_list') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
        const labels = [
         { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('publication_management.subscriber_id'), class: 'text-left' },
          { label: this.$t('publication_management.pub_type'), class: 'text-left' },
          { label: this.$t('publication_management.pub_title'), class: 'text-left' },
          { label: this.$t('external_research.subscription_info'), class: 'text-left' },
          { label: this.$t('external_research.pub_number'), class: 'text-center' },
          { label: this.$t('publication_management.fee_amount'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
         { key: 'index' },
          { key: 'subscribe_auto_id' },
          { key: 'type_name_bn' },
          { key: 'title_name_bn' },
          { key: 'subscription_date' },
          { key: 'pub_number' },
          { key: 'pub_total_fee' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'subscribe_auto_id' },
          { key: 'type_name' },
          { key: 'title_name' },
          { key: 'subscription_date' },
          { key: 'pub_number' },
          { key: 'pub_total_fee' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
   watch: {
    'search.pub_type_id': function (newVal) {
      this.publicationTitleList = this.getPublicationTitleList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.paymentStatusUpdate()
  },
  methods: {
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
        const status = this.$route.params.status
        const tranId = this.$route.query.transId
        await RestApi.getData(agriResearchServiceBaseUrl, subscriptionFormListApiPaymentStatus + status, { transId: tranId }).then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.loadData()
        this.$router.push('/research-farmer/publication-management/subscription-list')
      } else {
        this.loadData()
      }
    },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    view (item) {
      this.editItemId = item.id
    },
     getPublicationTitleList (pId) {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === pId)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, subscriptionListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpTitle = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.find(type => type.value === item.pub_title_id)
          const tmpType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.find(type => type.value === item.pub_type_id)
          const tmpFeeType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationFeeTypeList.find(type => type.value === parseInt(item.fee_type))
          const newData = {
              title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              type_name: tmpType !== undefined ? tmpType.text_en : '',
              type_name_bn: tmpType !== undefined ? tmpType.text_bn : '',
              fee_type_name: tmpFeeType !== undefined ? tmpFeeType.text_en : '',
              fee_type_name_bn: tmpFeeType !== undefined ? tmpFeeType.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
