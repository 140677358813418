import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = []
    const allRowsHead = [
      [
        { text: thisObject.$t('external_research.subscription_details'), style: 'th1', colSpan: 4 },
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('publication_management.pub_type'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.type_name : data.type_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('external_research.subscription_type'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.fee_type_name : data.fee_type_name_bn, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('external_research.number_of_subscription'), alignment: 'left', style: 'th' },
        { text: thisObject.$n(data.number_of_subscription), alignment: 'left', style: 'search' },
        { text: thisObject.$t('external_research.for_year'), alignment: 'left', style: 'th' },
        { text: thisObject.$n(data.year_no, { useGrouping: false }), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('globalTrans.subscription_start_date'), alignment: 'left', style: 'th' },
        { text: dateFormat(data.start_date), alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalTrans.subscription_end_date'), alignment: 'left', style: 'th' },
        { text: dateFormat(data.end_date), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('external_research.pub_number'), alignment: 'left', style: 'th' },
        { text: thisObject.$n(data.pub_number, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalTrans.total_amount') + ' ' + thisObject.$t('globalTrans.taka'), alignment: 'left', style: 'th' },
        { text: thisObject.$n(data.pub_total_fee, { useGrouping: false }), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('publication_management.subscriber_id'), alignment: 'left', style: 'th' },
        { text: data.subscribe_auto_id, alignment: 'left', style: 'search' },
        { text: thisObject.$t('external_research.mailing_address'), alignment: 'left', style: 'th' },
        { text: data.mailing_address, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('org_pro.is_regional_office'), alignment: 'left', style: 'th' },
        { text: thisObject.getColumnName(Store.state.ExternalUserIrrigation.commonObj.officeList, data.office_id), alignment: 'left', style: 'search' },
        {},
        {}
      ]
    ]
    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['25%', '25%', '25%', '25%'],
        body: allRowsHead
      }
    })
    pdfContent.push({ text: '', style: 'fertilizer' })
    if (data.isaddress_add) {
      const allRows2 = [
        [
          { text: thisObject.$t('external_research.subs_address_info'), style: 'th1', colSpan: 5 },
          {},
          {},
          {},
          {}
        ],
        [
          { text: thisObject.$t('globalTrans.sl_no'), style: 'td', alignment: 'center', bold: true },
          { text: thisObject.$t('globalTrans.name'), style: 'td', alignment: 'center', bold: true },
          { text: thisObject.$t('globalTrans.mobile'), style: 'td', alignment: 'center', bold: true },
          { text: thisObject.$t('globalTrans.address'), style: 'td', alignment: 'center', bold: true },
          { text: thisObject.$t('globalTrans.quantity'), style: 'td', alignment: 'center', bold: true }
        ]
      ]
      data.address_info.forEach((item, index) => {
        allRows2.push(
        [
          { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
          { text: item.name, style: 'td', alignment: 'center' },
          { text: mobileNumber(item.mobile), style: 'td', alignment: 'center' },
          { text: item.address, style: 'td', alignment: 'center' },
          { text: thisObject.$n(item.quantity), style: 'td', alignment: 'center' }
        ]
        )
      })
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['10%', '20%', '25%', '25%', '20%'],
          body: allRows2
        }
      })
      pdfContent.push({ text: '', style: 'fertilizer' })
    }
    const allRowsHeadprofile = [
      [
        { text: thisObject.$t('external_sidebar.profile') + ' ' + thisObject.$t('sidebar.information'), style: 'th1', colSpan: 4 },
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('globalTrans.name'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalUserData.mobile_no'), alignment: 'left', style: 'th' },
        { text: mobileNumber(data.mobile_no), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('org_pro_division.division'), alignment: 'left', style: 'th' },
        { text: thisObject.getColumnName(Store.state.ExternalUserIrrigation.commonObj.divisionList, data.division_id), alignment: 'left', style: 'search' },
        { text: thisObject.$t('org_pro_district.district'), alignment: 'left', style: 'th' },
        { text: thisObject.getColumnName(Store.state.ExternalUserIrrigation.commonObj.districtList, data.district_id), alignment: 'left', style: 'search' }
      ]
    ]
    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['25%', '25%', '25%', '25%'],
        body: allRowsHeadprofile
      }
    })
    pdfContent.push({ text: '', style: 'fertilizer' })
    const allRowsHeadChallan = [
      [
        { text: thisObject.$t('external_research.challan_info'), style: 'th1', colSpan: 4 },
        {},
        {},
        {}
      ],
      [
        { text: thisObject.$t('external_research.ekpay_trxid'), alignment: 'left', style: 'th' },
        { text: data.ekpay_trxid, alignment: 'left', style: 'search', colSpan: 3 },
        {},
        {}
      ],
      [
        { text: thisObject.$t('external_research.scroll_no'), alignment: 'left', style: 'th' },
        { text: data.scroll_no, alignment: 'left', style: 'search' },
        { text: thisObject.$t('external_research.ref_date'), alignment: 'left', style: 'th' },
        { text: dateFormat(data.ref_date), alignment: 'left', style: 'search' }
      ]
    ]
    pdfContent.push({
      table: {
        headerRows: 1,
        widths: ['25%', '25%', '25%', '25%'],
        body: allRowsHeadChallan
      }
    })
    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Portrait',
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 10,
          margin: [3, 3, 3, 3],
          bold: true
        },
        th2: {
          fillColor: '#dee2e6',
          fontSize: (i18n === 'bn') ? 10 : 10,
          margin: [3, 3, 3, 3],
          bold: true
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 10,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n === 'bn') ? 10 : 10,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [0, 10, 0, 7]
        },
        fertilizerSHeader: {
            fontSize: 10,
            margin: [40, 0, 0, 0]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fillColor: '#C6C2C1',
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 10]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        th1: {
          fillColor: '#055f05',
          color: '#FFFFFF',
          fontSize: 12,
          bold: true,
          margin: [3, 3, 3, 3]
        },
        header3: {
          fontSize: 9,
          margin: [0, 15, 0, 0]
        },
        address: {
          fontSize: 9,
          margin: [0, -10, 0, 0]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
      // pdfMake.createPdf(docDefinition, null, null, null).download('subscriber-list')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
