<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row class="details">
                <b-col lg="12" sm="12">
                     <b-row>
                        <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                            <b-button @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col md=12 id="form">
                                    <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('external_research.subscription_details')}}</legend>
                                        <b-col md=12>
                                            <b-table-simple striped bordered small hover>
                                                <tbody>
                                                        <b-tr>
                                                            <b-td width="20%">{{ $t('publication_management.pub_type') }}</b-td>
                                                            <b-td width="30%">{{ (currentLocale == 'bn') ? data.type_name_bn : data.type_name }}</b-td>
                                                            <b-td>{{$t('external_research.subscription_type')}}</b-td>
                                                            <b-td>{{  $i18n.locale == 'bn' ? data.fee_type_name_bn : data.fee_type_name }}</b-td>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-td width="20%">{{$t('external_research.number_of_subscription')}}</b-td>
                                                            <b-td width="30%">{{ $n(data.number_of_subscription) }}</b-td>
                                                            <b-td>{{$t('external_research.for_year')}}</b-td>
                                                            <b-td>{{ $n(data.year_no, { useGrouping: false }) }}</b-td>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-td width="20%">{{ $t('globalTrans.subscription_start_date') }}</b-td>
                                                            <b-td width="30%">{{ data.start_date | dateFormat }}</b-td>
                                                            <b-td width="20%">{{$t('globalTrans.subscription_end_date')}}</b-td>
                                                            <b-td width="30%">{{ data.end_date | dateFormat }}</b-td>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-td>{{$t('external_research.pub_number')}}</b-td>
                                                            <b-td>{{ $n(data.pub_number, { useGrouping: false }) }}</b-td>
                                                            <b-td>{{$t('globalTrans.total_amount') + ' ' + $t('globalTrans.taka')}}</b-td>
                                                            <b-td>{{ $n(data.pub_total_fee, { useGrouping: false }) }}</b-td>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-td>{{$t('publication_management.subscriber_id')}}</b-td>
                                                            <b-td>{{ data.subscribe_auto_id }}</b-td>
                                                            <b-td>{{$t('publication_management.mailing_address')}}</b-td>
                                                            <b-td>{{ data.mailing_address }}</b-td>
                                                        </b-tr>
                                                        <b-tr>
                                                            <b-td>{{ $t('org_pro.is_regional_office')}}</b-td>
                                                            <b-td>{{ getColumnName($store.state.ExternalUserIrrigation.commonObj.officeList, data.office_id) }}</b-td>
                                                            <b-td></b-td>
                                                            <b-td></b-td>
                                                        </b-tr>
                                                </tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-if="data.isaddress_add">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('external_research.subs_address_info')}}</legend>
                                        <b-col md=12>
                                                <table class="table" style="width:100%" border="1">
                                                    <thead class="thead">
                                                        <tr>
                                                            <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                                                            <th style="width:18%">{{$t('globalTrans.name')}}</th>
                                                            <th style="width:18%">{{$t('globalTrans.mobile')}}</th>
                                                            <th style="width:20%">{{$t('globalTrans.address')}}</th>
                                                            <th style="width:20%">{{$t('globalTrans.quantity')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tr v-for="(address, index) in data.address_info" :key="index">
                                                        <td style="width:7%">
                                                            {{ $n(index + 1) }}
                                                        </td>
                                                        <td style="width:18%">
                                                            {{ address.name }}
                                                        </td>
                                                        <td style="width:18%">{{ address.mobile | mobileNumber }}</td>
                                                        <td style="width:20%">
                                                            {{ address.address }}
                                                        </td>
                                                        <td style="width:20%">{{ $n(address.quantity) }}</td>
                                                    </tr>
                                                </table>
                                        </b-col>
                                    </fieldset>
                                    <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{ $t('external_sidebar.profile') + ' ' + $t('sidebar.information') }}</legend>
                                        <b-col md=12>
                                            <b-table-simple striped bordered small hover>
                                                <tbody>
                                                    <b-tr>
                                                        <b-td width="20%">{{ $t('globalTrans.name') }}</b-td>
                                                        <b-td width="30%">{{ (currentLocale == 'bn') ? data.name_bn : data.name }}</b-td>
                                                        <b-td>{{$t('globalUserData.mobile_no')}}</b-td>
                                                        <b-td>{{  data.mobile_no | mobileNumber }}</b-td>
                                                    </b-tr>
                                                     <b-tr>
                                                        <b-td width="20%">{{ $t('org_pro_division.division') }}</b-td>
                                                        <b-td width="30%">{{ getColumnName($store.state.ExternalUserIrrigation.commonObj.divisionList, data.division_id) }}</b-td>
                                                        <b-td>{{$t('org_pro_district.district')}}</b-td>
                                                        <b-td>{{ getColumnName($store.state.ExternalUserIrrigation.commonObj.districtList, data.district_id) }}</b-td>
                                                    </b-tr>
                                                </tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </fieldset>
                                    <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('external_research.challan_info')}}</legend>
                                        <b-col md=12>
                                            <b-table-simple striped bordered small hover>
                                                <tbody>
                                                    <b-tr>
                                                        <b-td width="20%">{{ $t('external_research.ekpay_trxid') }}</b-td>
                                                        <b-td colspan="3">{{ data.ekpay_trxid }}</b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td width="20%">{{$t('external_research.scroll_no')}}</b-td>
                                                        <b-td width="30%">{{ data.scroll_no }}</b-td>
                                                        <b-td>{{$t('external_research.ref_date')}}</b-td>
                                                        <b-td>{{ data.ref_date | dateFormat }}</b-td>
                                                    </b-tr>
                                                </tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </fieldset>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.close') }}</b-button>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ExportPdf from './export-pdf_details'

export default {
    props: ['id'],
    components: {
    },
    data () {
        return {
            data: {}
        }
    },
    created () {
        if (this.id) {
            const tmp = this.getData()
            this.data = tmp
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                return obj.text_bn
                }
                return obj.text_en
            } else {
                return ''
            }
        },
        getData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        pdfExport () {
            const reportTitle = this.$t('external_sidebar.subscription_list') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(reportTitle, this, this.data)
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
